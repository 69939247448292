.wp-block-button__link {
    color: #fff;
    background-color: #32373c;
    border-radius: 9999px;
    box-shadow: none;
    text-decoration: none;
    padding: calc(.667em + 2px) calc(1.333em + 2px);
    font-size: 1.125em
}

.wp-block-file__button {
    background: #32373c;
    color: #fff;
    text-decoration: none
}

@-webkit-keyframes bgslide {
    from {
        background-position-x: 0
    }

    to {
        background-position-x: -200%
    }
}

@keyframes bgslide {
    from {
        background-position-x: 0
    }

    to {
        background-position-x: -200%
    }
}

.wpp-widget-placeholder,
.wpp-widget-block-placeholder,
.wpp-shortcode-placeholder {
    margin: 0 auto;
    width: 60px;
    height: 3px;
    background: #dd3737;
    background: linear-gradient(90deg, #dd3737 0%, #571313 10%, #dd3737 100%);
    background-size: 200% auto;
    border-radius: 3px;
    -webkit-animation: bgslide 1s infinite linear;
    animation: bgslide 1s infinite linear
}

.entry-header h1:not(.section-title) {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.site-title a,
.site-title a:hover,
.site-title a:focus {
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/10/ndr_logo.svg) no-repeat;
    background-size: 100%;
}

.gform_wrapper .gf_invisible,
.gform_wrapper .gfield_visibility_hidden {
    visibility: hidden;
    position: absolute;
    left: -9999px
}

.columns-icons .title-content-wrap {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.columns-icons .blocks {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.columns-icons .blocks>div {
    display: flex;
    flex-direction: column;
}

.columns-icons .blocks .block-icon {
    min-height: 60px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.columns-icons .blocks .block-icon img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 60px;
}

.columns-icons .blocks .columns-icons-title {
    /* font-size: 24px;
        font-size: 2.4rem; */
    font-weight: 600;
    margin-bottom: 10px;
}

.columns-icons .blocks .columns-icons-subtitle {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.columns-icons .blocks .block-icon svg {
    color: #00abec;
    font-size: 60px;
    font-size: 6rem;
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.columns-icons .blocks.inline>div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
}

.columns-icons .blocks.inline .block-icon {
    margin: 5px 20px 0 0;
    min-width: 60px;
    min-height: unset;
    height: auto;
    flex: 0 1 100px;
    max-width: 100px;
}

.columns-icons .blocks.inline .block-icon img {
    max-width: 100%;
}

.columns-icons .blocks.inline .title-content-wrap {
    text-align: left;
    height: 100%;
}

.columns-icons .blocks.inline .hjs-buttons-group ul {
    justify-content: flex-start;
}

.columns-icons .blocks .hjs-buttons-group {
    margin-top: auto;
}

.columns-icons .blocks p:last-child {
    margin-bottom: 0;
}

#repayment-options .blocks>div {
    border: 1px solid #006ce3;
    border-radius: 16px;
    padding: 32px;
    text-align: left;
}

#repayment-options.columns-icons .title-content-wrap {
    text-align: left;
}

#repayment-options.columns-icons .title-content-wrap i:before,
#repayment-options.columns-icons .title-content-wrap em:before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2023/07/Creativity-Idea-Bulb-by-Streamlinehq.svg);
    background-repeat: no-repeat;
    background-size: 48px;
    margin-right: 16px;
}

#repayment-options.columns-icons .title-content-wrap i,
#repayment-options.columns-icons .title-content-wrap em {
    display: flex;
    align-items: center;
    font-style: normal;
}


#chances-to-win .wrap {
    border-top: 1px solid #D8DCE0;
    padding-top: 48px;
}

#chances-to-win+.fact.hjs-section .wrap {
    border-bottom: 1px solid #D8DCE0;
}

#chances-to-win+.fact .fact-box {
    text-align: center;
    border: 0;
    justify-content: center;
}

#chances-to-win .title-content-wrap {
    border-radius: 32px;
    border: 1px solid #006CE3;
    padding: 24px;
}

#chances-to-win .title-content-wrap p:first-child {
    color: #006CE3;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 500;
}

#chances-to-win .title-content-wrap p:last-child {
    font-size: 12px;
    font-size: 1.2rem;
}

#columns-icons-block_22345267eb2fefc26dc53773eb440dea .title-wrap:after {
    background: rgb(245, 240, 235)
}

.accordion-simple .accordion-blocks {
    max-width: 845px;
    margin-left: auto;
    margin-right: auto;
}

.accordion-simple.has-title .accordion-blocks {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.accordion-simple.has-title .accordion-blocks .first-column,
.accordion-simple.has-title .accordion-blocks .second-column {
    width: 48.5%;
    flex-grow: 1;
}

.accordion-simple.has-title .accordion-blocks .second-column {
    margin-left: 3%;
}

.accordion-simple label h2 {
    font-size: inherit;
    color: inherit;
    margin: 0;
    display: inline;
    line-height: inherit;
}

@media only screen and (max-width: 900px) {

    .accordion-simple .accordion-blocks .first-column,
    .accordion-simple .accordion-blocks .second-column {
        width: 100%;
        margin: 0;
    }

    .accordion-simple .accordion-blocks .tab:last-child {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 739px) {
    .accordion-simple.has-title .accordion-blocks {
        margin-top: 32px;
    }
}

#accordion-simple-block_e4bbc2d7c308c41d09578f13a99f0f28 .title-wrap:after {
    background: rgba(255, 255, 255, 0)
}

.call-to-action {
    background-position: center top !important;
}

.call-to-action .title-wrap {
    /* max-width: 620px; */
    margin: 0 auto;
    text-align: center;
}

.call-to-action .section-subtitle {
    margin-top: 22px;
    line-height: 2;
}

.call-to-action .section-description {
    max-width: 675px;
    max-width: 890px;
    margin: 22px auto 0;
}

.call-to-action .title-wrap.right {
    float: right;
    text-align: right;
}

.call-to-action .title-wrap.right h2,
.call-to-action .title-wrap.right h3,
.call-to-action .title-wrap.right p,
.call-to-action .title-wrap.right .hjs-buttons-group ul {
    text-align: right;
    justify-content: flex-end;
}

.call-to-action .title-wrap.left {
    float: left;
    text-align: left;
}

.call-to-action .title-wrap.left h2,
.call-to-action .title-wrap.left h3,
.call-to-action .title-wrap.left p,
.call-to-action .title-wrap.left .hjs-buttons-group ul {
    text-align: left;
    justify-content: flex-start;
}

/* .call-to-action h2:after {
        content: "";
        display: block;
        border-bottom: 3px solid;
        padding-top: 20px;
        max-width: 700px;
        width: 100%;
        margin: 0 auto 20px;
    } */
.call-to-action .title-wrap p,
.call-to-action .title-wrap h3,
.call-to-action .title-wrap h4,
.call-to-action .title-wrap h5 {
    margin: 0 0 28px;
    clear: both;
}

.call-to-action .title-wrap p:last-child,
.call-to-action .title-wrap h3:last-child,
.call-to-action .title-wrap h4:last-child,
.call-to-action .title-wrap h5:last-child {
    margin-bottom: 0;
}

.call-to-action .wrap:before,
.call-to-action .wrap:after {
    content: none;
}

.call-to-action h2 {}

.call-to-action p {
    /* font-size: 20px;
        font-size: 2rem; */
}

.call-to-action .title-wrap p img {
    margin: 10px 0;
}

.call-to-action .hjs-buttons-group {
    margin-top: 30px;
}


.call-to-action .title-wrap.inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: -10px;
    padding: 30px 0;
}

.call-to-action .title-wrap.inline .section-description,
.call-to-action .title-wrap.inline .hjs-buttons-group {
    margin: 10px;
}

.call-to-action .title-wrap.inline h2 {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
}

.call-to-action .title-wrap.inline h2 a {
    font-size: 30px;
    font-size: 3rem;
    font-weight: 700;
    box-shadow: none;
    margin: 0 10px 0 0;
}

.call-to-action .hjs-bottom-logos {
    margin: 0 auto;
    max-width: 800px;
}

.call-to-action .title-wrap~.hjs-bottom-logos {
    margin: 40px auto 0;
}

.call-to-action h3 {
    color: #0077e3;
    font-size: 32px;
    font-size: 3.2rem;
}

.call-to-action.contact-cta a[href*="tel"] {
    color: #0077e3;
    font-size: 42px;
    font-size: 4.2rem;
    text-decoration: none;
    font-family: 'Avenir Next Demi';
    margin-bottom: 20px;
    display: inline-block;
}

.call-to-action.contact-cta p {
    color: #525666;
    color: #75757C;
    font-size: 18px;
    font-size: 1.8rem;
}

.call-to-action .section-title span {
    color: #0074E0;
}

#sch-questions-cta .section-title:before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto 32px;
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2023/03/Messages-People-Woman-Question-by-Streamlinehq.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

@media only screen and (max-width: 900px) {

    /* .call-to-action .section-description {
            font-size: 21px;
            font-size: 2.1rem;
        } */
    .call-to-action h3 {
        font-size: 28px;
        font-size: 2.8rem;
    }
}

@media only screen and (max-width: 739px) {
    .call-to-action .read-more {
        font-size: 18px;
    }

    .call-to-action .title-wrap {
        background: none;
    }

    .call-to-action .title-wrap {
        max-width: 100%;
    }

    .call-to-action.contact-cta a[href*="tel"] {
        background-color: #0077e3;
        border: 2px solid #0077e3;
        color: #fff;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 11px 35px;
        border-radius: 40px;
        overflow: hidden;
        line-height: 1.2;
        box-shadow: 0 20px 20px -10px rgb(0 119 227 / 20%);
    }

    .call-to-action .hjs-buttons-group {
        margin-top: 20px;
    }

    .call-to-action .section-description {
        margin: 12px auto 0;
    }

    #sch-questions-cta .section-title:before {
        width: 48px;
        height: 48px;
        margin: 0 auto 16px;
    }
}

#narrow-cta .title-wrap:after {
    background: rgb(245, 240, 235)
}

#testimonial-cards-alt-block_adc9b90d6dd33d0e829ec2d930ca39f8 .wrap {
    max-width: 1280px;
}

.testimonial-cards-alt .title-wrap {
    padding: 0;
}

@media only screen and (max-width: 1319px) {
    #testimonial-cards-alt-block_adc9b90d6dd33d0e829ec2d930ca39f8 .wrap {
        padding-left: 3% !important;
        padding-right: 3% !important;
    }
}

@media only screen and (max-width: 1023px) {
    #testimonial-cards-alt-block_adc9b90d6dd33d0e829ec2d930ca39f8 .wrap {
        padding-left: 4% !important;
        padding-right: 4% !important;
    }
}

@media only screen and (max-width: 739px) {
    #testimonial-cards-alt-block_adc9b90d6dd33d0e829ec2d930ca39f8 .wrap {
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
}

.entry-content .alignfull.testimonial-cards-alt {
    max-width: MIN(100vw, 1920px);
    margin-left: MAX( -100vw / 2 + 100% / 2, -1920px / 2 + 100% / 2 );
    margin-right: MAX( -100vw / 2 + 100% / 2, -1920px / 2 + 100% / 2 );
}
.testimonial-cards-alt .wrap {
    max-width: 100%;
    overflow: hidden;
    padding: 0!important;
}
.testimonial-cards-alt.has-title .testimonial-slider-wrap {
    margin-top: 40px;
}
.testimonial-cards-alt .testimonial-slider-wrap {
    max-width: 750px;
    margin: 0 auto;
    visibility: hidden;
    position: static;
}
.testimonial-cards-alt .testimonials-wrap {
    overflow: hidden;
    position: relative;
}
.testimonial-cards-alt .testimonial-slider-wrap.slick-initialized {
    visibility: visible;
}
.testimonial-cards-alt .slick-list {
    overflow: visible;
}
.testimonial-cards-alt .slick-initialized .slick-slide {
    padding: 0 15px;
    outline: none;
    height: auto;
    opacity: 0.5;
}
.testimonial-cards-alt .slick-initialized .slick-slide.slick-current {
    opacity: 1;
}
.testimonial-cards-alt .card {
    /* overflow: visible; */
    border-radius: 24px;
    box-shadow: 0px 5px 5px 1px rgb(0 0 0 / 10%);
    max-width: 100%;
}
.testimonial-cards-alt .card .image-wrap img {
    width: 100%;
}
.testimonial-cards-alt .testimonial-slide .reviewer-photo img {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 10px 10px 0px rgb(1 0 82 / 15%);
    border-radius: 50%;
    max-width: 115px;
    margin-bottom: -57px;
    top: -57px;
}
.testimonial-cards-alt .content-wrap {
    padding: 30px;
    text-align: center;
}
.testimonial-cards-alt cite {
    font-style: normal;
    color: #75757C;
    font-size: 18px;
    font-size: 1.8rem;
    display: block;
    line-height: 1.4;
    font-weight: 400;
}
.testimonial-cards-alt blockquote {
    border: 0;
    margin: 35px 0 0;
    padding: 0;
    /* font-style: normal; */
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(31, 33, 71);
}
.testimonial-cards-alt .slick-track {
    display: flex;
}
.testimonial-cards-alt .debt-details {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
}

.testimonial-cards-alt .debt-details .label {
    display: block;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #1A1B24;
    color: #1F2147;
}

.testimonial-cards-alt .debt-detail-row {
    border-right: 1px solid #BCC3C9;
    padding-right: 30px;
    margin-right: 30px;
    padding: 15px 30px 15px 0;
}

.testimonial-cards-alt .debt-detail-row:last-child {
    padding-right: 0;
    margin-right: 0;
    border: 0;
}

.testimonial-cards-alt .debt-details .value {
    color: #1A1B24;
    font-size: 20px;
    font-size: 2rem;
    font-family: 'Avenir Next Demi', sans-serif;
}
.testimonial-cards-alt .debt-details .savings-perc .value {
    color: #006CE3;
}
.testimonial-cards-alt span.gap {
    /* flex-grow: 1; */
    /* border-bottom: 1px solid #525666; */
    /* margin: 0 10px 6px; */
}
p.highlight-msg {
    font-size: 24px;
    font-size: 2.4rem;
    text-align: center;
    font-family: 'Avenir Next Demi', sans-serif;
    color: #0074E0;
    margin: 30px 0 0;
    font-weight: 700;
}
.bottom-shadow {
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
}
.bottom-shadow span {
    box-shadow: 0px 0px 15px 7px rgb(0 0 78 / 15%);
    display: block;
    width: 100%;
}
.simple-layout .bottom-shadow {
    display: none;
}
.simple-layout blockquote p:last-child {
    margin-bottom: 0;
}
.testimonial-cards-alt .slick-dots {
    margin-top: 50px;
}
.testimonial-cards-alt .slick-prev,
.testimonial-cards-alt .slick-next {
    height: 80px;
    width: 80px;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    font-size: 6rem;
    background: #0074E0;
    padding: 0;
    border-radius: 0;
    border: 0;
}
.testimonial-cards-alt .slick-arrow img {
    width: 30px;
}
.testimonial-cards-alt .slick-arrow:hover {
    background: #006ac9;
}
.testimonial-cards-alt .slick-prev svg,
.testimonial-cards-alt .slick-next svg {
    fill: #fff;
    width: 45px;
    height: 52px;
}

.testimonial-slider-wrap.no-slider {
    max-width: 960px;
    visibility: visible;
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px!important;
}
.testimonial-slider-wrap.no-slider.columns-3 {
    max-width: 1280px;
}
.testimonial-slider-wrap.no-slider .testimonial-slide {
    width: 48%;
    margin-left: 4%;
}
.testimonial-slider-wrap.no-slider.columns-3 .testimonial-slide {
    width: 32%;
    margin-left: 2%!important;
    margin-top: 0!important;
}
.testimonial-slider-wrap.no-slider.columns-3 .testimonial-slide:nth-of-type(3n+1) {
    margin-left: 0!important;
}
.testimonial-slider-wrap.no-slider.columns-3 .testimonial-slide:nth-of-type(n+4) {
    margin-top: 40px!important
}
.testimonial-cards-alt .columns-3 .content-wrap {
    padding: 24px 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.testimonial-cards-alt .columns-3 cite {
    font-size: 12px;
    font-size: 1.2rem;
}
.testimonial-cards-alt .columns-3 blockquote {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 8px;
}
.testimonial-cards-alt .columns-3 blockquote p {
    margin-bottom: 16px;
}
.testimonial-cards-alt .no-slider.columns-3 .debt-details {
    max-width: 100%;
    flex-wrap: nowrap;
    margin-top: auto;
    width: 100%;
}
.testimonial-cards-alt .no-slider.columns-3 .debt-detail-row {
    width: 33.33%;
    border: 1px solid #BCC3C9;
    border-width: 0 0 0 1px!important;
    text-align: left;
    padding: 0 8px 0 8px!important;
    width: auto;
    flex-grow: 1;
}
.testimonial-cards-alt .columns-3 .debt-details .value {
    font-size: 18px;
    font-size: 1.8rem;
}

.testimonial-slider-wrap.no-slider .testimonial-slide:first-of-type {
    margin-left: 0;
}
.testimonial-slider-wrap .slick-slide img {
    width: 100%;
}
.testimonial-slider-wrap.no-slider .testimonial-slide:nth-of-type(2n+1) {
    margin-left: 0;
}

.testimonial-slider-wrap.no-slider .testimonial-slide:nth-of-type(n+3) {
    margin-top: 40px;
}
.testimonial-cards-alt .no-slider .debt-details {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    max-width: 265px;
    margin: 0 auto;
}
.testimonial-cards-alt .no-slider .debt-detail-row {
    margin-right: 0;
    padding: 0;
    width: 50%;
    border-width: 0;
}
.testimonial-cards-alt .no-slider .debt-detail-row:nth-of-type(2n+1) {
    border-right-width: 1px;
    padding-right: 30px;
}

.testimonial-cards-alt .no-slider .debt-detail-row:nth-of-type(n+3) {
    border-top: 1px solid #75757C;
    padding-top: 16px;
}

.testimonial-cards-alt .no-slider .debt-detail-row:nth-of-type(-n+2) {
    padding-bottom: 16px;
}

.testimonial-cards-alt .no-slider .debt-detail-row:nth-of-type(2n) {
    padding-left: 30px;
}

@media only screen and (max-width: 1023px) {
    .testimonial-slider-wrap.no-slider.columns-3 .testimonial-slide {
        width: 100%!important;
        margin-left: 0!important;
    }
    .testimonial-slider-wrap.no-slider.columns-3 .testimonial-slide:nth-of-type(n+2) {
        margin-top: 40px!important
    }
}

@media only screen and (max-width: 900px) {
    .testimonial-cards-alt .testimonial-slider-wrap {
        /* max-width: 100%; */
    }
    .testimonial-cards-alt .slick-initialized .slick-slide {
        /* padding: 70px 50px 0 0; */
    }
    .testimonial-cards-alt.has-title .testimonial-slider-wrap {
        /* margin-top: 15px; */
    }
    .testimonial-cards-alt .title-wrap {
        padding: 0 4%;
    }
}
@media only screen and (max-width: 739px) {
    .testimonial-slider-wrap.no-slider .testimonial-slide {
        width: 100%;
        margin-left: 0;
    }
    .testimonial-slider-wrap.no-slider .testimonial-slide:not(:first-of-type) {
        margin-top: 90px;
    }
    .apply-loan-st .testimonial-slider-wrap.no-slider .testimonial-slide:not(:first-of-type) {
        margin-top: 20px;
    }
    .testimonial-cards-alt .testimonial-slide:not(.simple-layout) .image-wrap {
        min-height: 260px;
    }
    .testimonial-cards-alt .testimonial-slide:not(.simple-layout) img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
    .testimonial-cards-alt .content-wrap {
        padding: 24px 16px;
    }
    .testimonial-cards-alt cite {
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.5;
    }
    .testimonial-cards-alt blockquote {
        margin: 24px 0 0;
        font-size: 18px;
        font-size: 1.8rem;
        line-height: 1.25;
    }
    .testimonial-cards-alt .simple-layout .content-wrap {
        padding: 16px 16px;
    }
    .testimonial-cards-alt .simple-layout blockquote {
        margin: 16px 0 0;
    }
    .testimonial-cards-alt p.highlight-msg {
        font-size: 20px;
        font-size: 2rem;
        margin: 30px 0 0;
        line-height: 1.4;
    }
    .testimonial-cards-alt .debt-details {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        max-width: 265px;
        margin: 0 auto;
    }
    .testimonial-cards-alt .debt-detail-row {
        margin-right: 0;
        padding: 0;
        width: 50%;
        border-width: 0;
    }
    .testimonial-cards-alt .debt-detail-row:nth-of-type(2n+1) {
        border-right-width: 1px;
        padding-right: 30px;
    }

    .testimonial-cards-alt .debt-detail-row:nth-of-type(n+3) {
        border-top: 1px solid #75757C;
        padding-top: 16px;
    }

    .testimonial-cards-alt .debt-detail-row:nth-of-type(-n+2) {
        padding-bottom: 16px;
    }

    .testimonial-cards-alt .debt-detail-row:nth-of-type(2n) {
        padding-left: 30px;
    }
    .testimonial-cards-alt .slick-dots {
        margin-top: 40px;
    }
    .testimonial-cards-alt .section-bottom-content{
        margin-top: 10px;
    }
}
@media only screen and (max-width: 600px) {
    .testimonial-cards-alt .slick-initialized .slick-slide {
        /* padding: 70px 38px 0 0; */
    }
}
@media only screen and (max-width: 479px) {
    .testimonial-cards-alt .slick-initialized .slick-slide {
        /* padding: 70px 30px 0 0; */
    }
}
#testimonial-cards-alt-block_adc9b90d6dd33d0e829ec2d930ca39f8 .title-wrap:after {
    background: rgb(31,33,71)    }
    #call-to-action-block_0e35078bf08280c667319e24ba588e63 .title-wrap:after {
        background: rgba(255,255,255, 0)    }
        .gform_wrapper .gf_invisible,
        .gform_wrapper .gfield_visibility_hidden{
            visibility:hidden;position:absolute;left:-9999px
        }


        /**
 * This file is major compenent of this plugin so please don't try to edit here.
 */
#rmp_menu_trigger-34289, #rmp-search-box-34289, #rmp-menu-title-34289, #rmp-menu-additional-content-34289 {
    display: none; }
  
  #rmp-header-bar-34289 {
    position: fixed;
    background-color: #212121;
    color: #ffffff;
    font-size: 14px;
    height: 80px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    font-family: inherit;
    display: none; }
    #rmp-header-bar-34289 .rmp-header-bar-item {
      margin-right: 2%; }
      #rmp-header-bar-34289 .rmp-header-bar-item .rmp-menu-header-logo {
        width: "%";
        height: "px"; }
    #rmp-header-bar-34289 a {
      color: #ffffff; }
    #rmp-header-bar-34289 .rmp-header-bar-title {
      color: #fff;
      font-size: 18px;
      font-weight: 100; }
    #rmp-header-bar-34289.header-scolling-bg-color {
      background-color: ""; }
  
  @media screen and (max-width: 8000px) {
    #rmp-header-bar-34289 {
      display: block; } }
  
  #rmp_menu_trigger-34289 {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 40px;
    border-radius: 0px;
    display: none;
    text-decoration: none;
    right: 5%;
    background: #212121;
    transition: transform 0.5s, background-color 0.5s; }
    #rmp_menu_trigger-34289:hover, #rmp_menu_trigger-34289:focus {
      background: #333;
      text-decoration: unset; }
    #rmp_menu_trigger-34289.is-active {
      background: #333333; }
    #rmp_menu_trigger-34289 .rmp-trigger-box {
      width: 25px;
      color: #fff; }
    #rmp_menu_trigger-34289 .rmp-trigger-icon-active, #rmp_menu_trigger-34289 .rmp-trigger-text-open {
      display: none; }
    #rmp_menu_trigger-34289.is-active .rmp-trigger-icon-active, #rmp_menu_trigger-34289.is-active .rmp-trigger-text-open {
      display: inline; }
    #rmp_menu_trigger-34289.is-active .rmp-trigger-icon-inactive, #rmp_menu_trigger-34289.is-active .rmp-trigger-text {
      display: none; }
    #rmp_menu_trigger-34289 .rmp-trigger-label {
      color: #fff;
      pointer-events: none;
      line-height: 13px;
      font-family: inherit;
      font-size: 14px;
      display: inline;
      text-transform: inherit; }
      #rmp_menu_trigger-34289 .rmp-trigger-label.rmp-trigger-label-top {
        display: block;
        margin-bottom: 12px; }
      #rmp_menu_trigger-34289 .rmp-trigger-label.rmp-trigger-label-bottom {
        display: block;
        margin-top: 12px; }
    #rmp_menu_trigger-34289 .responsive-menu-pro-inner {
      display: block; }
    #rmp_menu_trigger-34289 .responsive-menu-pro-inner, #rmp_menu_trigger-34289 .responsive-menu-pro-inner::before, #rmp_menu_trigger-34289 .responsive-menu-pro-inner::after {
      width: 25px;
      height: 3px;
      background-color: #221122;
      border-radius: 4px;
      position: absolute; }
    #rmp_menu_trigger-34289.is-active .responsive-menu-pro-inner, #rmp_menu_trigger-34289.is-active .responsive-menu-pro-inner::before, #rmp_menu_trigger-34289.is-active .responsive-menu-pro-inner::after {
      background-color: #fff; }
    #rmp_menu_trigger-34289:hover .responsive-menu-pro-inner, #rmp_menu_trigger-34289:hover .responsive-menu-pro-inner::before, #rmp_menu_trigger-34289:hover .responsive-menu-pro-inner::after {
      background-color: #fff; }
  
  /* Hamburger menu styling */
  @media screen and (max-width: 8999px) {
    #rmp-page-overlay-34289 {
      background: rgba(0, 0, 0, 0) 5;
      transition: visibility 0.5s linear, opacity 0.5s linear; }
      #rmp-page-overlay-34289.is-active {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.5s linear, opacity 0.5s linear; }
    #rmp_menu_trigger-34289 {
      display: block; }
    #rmp-container-34289 {
      position: fixed;
      top: 0;
      margin: 0;
      transition: transform 0.5s;
      overflow: auto;
      width: 85%;
      max-width: 448px;
      min-width: 280px;
      background-color: #212121;
      background-image: url('');
      height: 100%;
      left: 0;
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      padding-right: 0px; }
      #rmp-container-34289.rmp-fade-top, #rmp-container-34289.rmp-fade-left, #rmp-container-34289.rmp-fade-right, #rmp-container-34289.rmp-fade-bottom {
        display: none; }
    #rmp-menu-wrap-34289 {
      padding-top: 5%;
      padding-left: 10%;
      padding-bottom: 1%;
      padding-right: 10%;
      background-color: rgba(0, 0, 0, 0); }
      #rmp-menu-wrap-34289 .rmp-menu, #rmp-menu-wrap-34289 .rmp-submenu {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0; }
      #rmp-menu-wrap-34289 .rmp-submenu.rmp-submenu-open {
        display: block; }
      #rmp-menu-wrap-34289 .rmp-mega-menu-panel {
        width: 100%; }
      #rmp-menu-wrap-34289 .rmp-mega-menu-row {
        display: flex;
        flex-direction: column;
        overflow: hidden; }
        #rmp-menu-wrap-34289 .rmp-mega-menu-row .rmp-mega-menu-col {
          width: 100%; }
      #rmp-menu-wrap-34289 .rmp-menu-item {
        width: 100%;
        list-style: none;
        margin: 0; }
      #rmp-menu-wrap-34289 .rmp-menu-item-link {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border-bottom: 0px solid #212121;
        font-family: inherit;
        color: #fff;
        text-align: left;
        background: "";
        font-weight: 500;
        letter-spacing: 0px;
        display: block;
        width: 100%;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s, border-color 0.5s, 0.5s;
        padding: 0 5%;
        padding-right: 50px; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link:after, #rmp-menu-wrap-34289 .rmp-menu-item-link:before {
          display: none; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link:hover {
          color: #fff;
          border-color: #212121;
          background: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link:focus {
          outline: none;
          border-color: unset;
          box-shadow: unset; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link .rmp-font-icon {
          height: 40px;
          line-height: 40px;
          margin-right: 10px;
          font-size: 14px; }
      #rmp-menu-wrap-34289 .rmp-menu-current-item .rmp-menu-item-link {
        color: #fff;
        border-color: #212121;
        background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-current-item .rmp-menu-item-link:hover {
          color: #fff;
          border-color: #3f3f3f;
          background-color: ""; }
      #rmp-menu-wrap-34289 .rmp-menu-subarrow {
        position: absolute;
        top: 0;
        bottom: 0;
        text-align: center;
        overflow: hidden;
        background-size: cover;
        overflow: hidden;
        right: 0;
        border-left-style: solid;
        border-left-color: "";
        border-left-width: 0px;
        height: 40px;
        width: 40px;
        color: #fff;
        background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-subarrow:hover {
          color: #fff;
          border-color: "";
          background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-subarrow .rmp-font-icon {
          margin-right: unset; }
        #rmp-menu-wrap-34289 .rmp-menu-subarrow * {
          vertical-align: middle;
          line-height: 40px; }
      #rmp-menu-wrap-34289 .rmp-menu-subarrow-active {
        display: block;
        background-size: cover;
        color: #fff;
        border-color: "";
        background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-subarrow-active:hover {
          color: #fff;
          border-color: "";
          background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-subarrow-active .rmp-go-back {
          display: block; }
      #rmp-menu-wrap-34289 .rmp-submenu {
        display: none; }
        #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link {
          height: px;
          line-height: 40px;
          font-size: 13px;
          letter-spacing: 0px;
          border-bottom: 0px solid "";
          font-family: inherit;
          color: #fff;
          text-align: left;
          background-color: ""; }
          #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link:hover {
            color: #fff;
            border-color: "";
            background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-current-item .rmp-menu-item-link {
          color: #fff;
          border-color: "";
          background-color: ""; }
          #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-current-item .rmp-menu-item-link:hover {
            color: #fff;
            border-color: "";
            background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-subarrow {
          right: 0;
          border-right: unset;
          border-left-style: solid;
          border-left-width: 0px;
          border-left-color: "";
          height: "px";
          line-height: "px";
          width: "px";
          color: "";
          background-color: ""; }
          #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-subarrow:hover {
            color: "";
            border-color: "";
            background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-subarrow-active {
          color: "";
          border-color: "";
          background-color: ""; }
          #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-subarrow-active:hover {
            color: "";
            border-color: "";
            background-color: ""; }
      #rmp-menu-wrap-34289 .rmp-menu-item-description {
        margin: 0;
        padding: 5px 5%;
        opacity: 0.8;
        color: #fff; }
      #rmp-menu-wrap-34289 .rmp-submenu-depth-1 .rmp-menu-item-link {
        padding-left: 10%; }
      #rmp-menu-wrap-34289 .rmp-submenu-depth-2 .rmp-menu-item-link {
        padding-left: "%"; }
      #rmp-menu-wrap-34289 .rmp-submenu-depth-3 .rmp-menu-item-link {
        padding-left: "%"; }
      #rmp-menu-wrap-34289 .rmp-submenu-depth-4 .rmp-menu-item-link {
        padding-left: 25%; }
    #rmp-search-box-34289 {
      display: block;
      padding-top: 5%;
      padding-left: 10%;
      padding-bottom: 5%;
      padding-right: 10%; }
      #rmp-search-box-34289 .rmp-search-form {
        margin: 0; }
      #rmp-search-box-34289 .rmp-search-box {
        background: rgba(255, 255, 255, 0.26);
        border: 1px solid rgba(255, 255, 255, 0.28);
        color: #ffffff;
        width: 100%;
        padding: 0 5%;
        border-radius: 0;
        height: 45px;
        -webkit-appearance: none; }
        #rmp-search-box-34289 .rmp-search-box::placeholder {
          color: #ffffff; }
        #rmp-search-box-34289 .rmp-search-box:focus {
          background-color: rgba(255, 255, 255, 0.26);
          outline: 2px solid rgba(255, 255, 255, 0.28);
          color: #ffffff;
          /** Menu Title Style */ }
    #rmp-menu-title-34289 {
      color: #fff;
      text-align: center;
      font-size: 18px;
      padding-top: 10%;
      padding-left: 10%;
      padding-bottom: 0%;
      padding-right: 10%;
      font-weight: 100;
      display: flex;
      flex-direction: column;
      transition: background-color 0.5s, border-color 0.5s, color 0.5s; }
      #rmp-menu-title-34289:hover {
        background-color: "";
        color: #fff; }
      #rmp-menu-title-34289 .rmp-font-icon {
        font-size: 18px; }
      #rmp-menu-title-34289 > a {
        color: #fff;
        width: 100%;
        background-color: unset;
        text-decoration: none; }
        #rmp-menu-title-34289 > a:hover {
          color: #fff; }
      #rmp-menu-title-34289 .rmp-menu-title-image {
        max-width: 100%;
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
        display: block;
        margin: auto;
        margin-bottom: 15px;
        /** Menu Additional Content Style */ }
    #rmp-menu-additional-content-34289 {
      padding-top: 1%;
      padding-left: 1%;
      padding-bottom: 1%;
      padding-right: 1%;
      display: block;
      color: #00004e;
      text-align: center;
      word-break: break-word;
      font-size: 10px; } }
  
  /* Desktop Styling */
  @media screen and (min-width: 8999px) {
    #rmp-container-34289, #rmp-header-bar-34289, #rmp_menu_trigger-34289 {
      display: none; }
      #rmp-header-bar-34289 #rmp-container-34289 {
        background: unset; }
    #rmp-container-34289 {
      position: absolute;
      text-align: left;
      left: 0;
      top: 0;
      background-color: #212121;
      padding: 0 5%;
      z-index: 99997; }
      #rmp-container-34289.rmp-container {
        transform: translateX(0);
        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0); }
      #rmp-container-34289 #rmp_menu_trigger-34289, #rmp-container-34289 #rmp-menu-title-34289, #rmp-container-34289 #rmp-menu-additional-content-34289, #rmp-container-34289 #rmp-search-box-34289 {
        display: none; }
    #rmp-menu-wrap-34289 {
      background-color: rgba(0, 0, 0, 0);
      display: inline-block; }
      #rmp-menu-wrap-34289 .rmp-menu, #rmp-menu-wrap-34289 .rmp-submenu {
        width: auto;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex; }
      #rmp-menu-wrap-34289 .rmp-menu {
        flex-flow: wrap; }
      #rmp-menu-wrap-34289 .rmp-menu, #rmp-menu-wrap-34289 .rmp-submenu {
        overflow: inherit; }
        #rmp-menu-wrap-34289 .rmp-menu .rmp-go-back, #rmp-menu-wrap-34289 .rmp-submenu .rmp-go-back {
          display: none; }
      #rmp-menu-wrap-34289 .rmp-submenu {
        flex-direction: column;
        display: none;
        position: absolute;
        min-width: 180px; }
        #rmp-menu-wrap-34289 .rmp-submenu li {
          list-style-type: none;
          line-height: normal; }
        #rmp-menu-wrap-34289 .rmp-submenu .rmp-submenu {
          left: 100%;
          top: 0; }
        #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link {
          height: 40px;
          line-height: 40px; }
      #rmp-menu-wrap-34289 .rmp-mega-menu-container.rmp-submenu {
        z-index: 99; }
      #rmp-menu-wrap-34289 .rmp-menu-subarrow {
        display: none; }
        #rmp-menu-wrap-34289 .rmp-menu-item-has-children .rmp-menu-item {
          position: relative; }
      #rmp-menu-wrap-34289 .rmp-menu-item {
        width: auto;
        list-style: none;
        margin: 0; }
      #rmp-menu-wrap-34289 .menu-item-has-children:hover > .rmp-submenu {
        display: flex;
        animation: none 100ms; }
      #rmp-menu-wrap-34289 .rmp-menu-item-link {
        font-family: inherit;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #fff;
        background: "";
        text-align: left;
        font-weight: 500;
        letter-spacing: 0px;
        display: inline-block;
        text-decoration: none;
        padding: 0 1rem;
        width: auto;
        white-space: nowrap;
        margin: 0 1px;
        transition: background-color 0.5s, border-color 0.5s, 0.5s; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link:after, #rmp-menu-wrap-34289 .rmp-menu-item-link:before {
          display: none; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link:hover {
          border-color: #212121;
          color: #fff;
          background: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link:focus {
          outline: none;
          border-color: unset;
          box-shadow: unset; }
        #rmp-menu-wrap-34289 .rmp-menu-item-link .rmp-font-icon {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          margin-right: 10px; }
      #rmp-menu-wrap-34289 .rmp-menu-current-item .rmp-menu-item-link {
        color: #fff;
        border-color: #212121;
        background-color: ""; }
        #rmp-menu-wrap-34289 .rmp-menu-current-item .rmp-menu-item-link:hover {
          color: #fff;
          border-color: #3f3f3f;
          background-color: ""; }
    #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link {
      font-family: inherit;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      color: #fff;
      background-color: "";
      width: 100%;
      border-bottom: "px" solid "";
      text-align: left; }
      #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link:hover {
        border-color: "";
        color: #fff;
        background-color: ""; } }
  
  @media screen and (max-width: 600px) {
    #rmp-menu-wrap-34289 .rmp-menu-item-link {
      font-family: inherit;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #fff; }
      #rmp-menu-wrap-34289 .rmp-menu-item-link:hover {
        color: #fff; }
    #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link {
      font-family: inherit;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      color: #fff; }
      #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link:hover {
        color: #fff; } }
  
  @media screen and (max-width: 8999px) and (min-width: 600px) {
    #rmp-menu-wrap-34289 .rmp-menu-item-link {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #fff; }
      #rmp-menu-wrap-34289 .rmp-menu-item-link:hover {
        color: #fff; }
      #rmp-menu-wrap-34289 .rmp-menu-item-link .rmp-font-icon {
        height: 40px;
        line-height: 40px;
        font-size: 14px; }
    #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      color: #fff; }
      #rmp-menu-wrap-34289 .rmp-submenu .rmp-menu-item-link:hover {
        color: #fff; } }
  
  .rmp-mega-menu-panel {
    background: #000;
    display: block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    overflow: hidden; }
  
  .rmp-mega-menu-row {
    display: flex;
    flex-direction: row;
    color: #fff;
    overflow: hidden; }
  
  .rmp-submenu-animate-on {
    animation: none 100ms; }
  /*  Menu Trigger Boring Animation */
  .rmp-menu-trigger-boring .responsive-menu-pro-inner {
    transition-property: none; }
    .rmp-menu-trigger-boring .responsive-menu-pro-inner::after, .rmp-menu-trigger-boring .responsive-menu-pro-inner::before {
      transition-property: none; }
  .rmp-menu-trigger-boring.is-active .responsive-menu-pro-inner {
    transform: rotate(45deg); }
    .rmp-menu-trigger-boring.is-active .responsive-menu-pro-inner:before {
      top: 0;
      opacity: 0; }
    .rmp-menu-trigger-boring.is-active .responsive-menu-pro-inner:after {
      bottom: 0;
      transform: rotate(-90deg); }
  
  /*  Menu Trigger Spin Animation */
  .rmp-menu-trigger-spin .responsive-menu-pro-inner {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spin .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  
  .rmp-menu-trigger-spin .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spin.is-active .responsive-menu-pro-inner {
    transform: rotate(225deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  .rmp-menu-trigger-spin.is-active .responsive-menu-pro-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  
  .rmp-menu-trigger-spin.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger Spin Reverse Animation */
  .rmp-menu-trigger-spin-r .responsive-menu-pro-inner {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spin-r .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  
  .rmp-menu-trigger-spin-r .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spin-r.is-active .responsive-menu-pro-inner {
    transform: rotate(-225deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  .rmp-menu-trigger-spin-r.is-active .responsive-menu-pro-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  
  .rmp-menu-trigger-spin-r.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger Minus Animation */
  .rmp-menu-trigger-minus .responsive-menu-pro-inner::before, .rmp-menu-trigger-minus .responsive-menu-pro-inner::after {
    transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-minus .responsive-menu-pro-inner::before {
    top: 8px; }
  
  .rmp-menu-trigger-minus .responsive-menu-pro-inner::after {
    top: -8px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-minus.is-active .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-minus.is-active .responsive-menu-pro-inner::after {
    opacity: 0;
    transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-minus.is-active .responsive-menu-pro-inner::before {
    top: 0; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-minus.is-active .responsive-menu-pro-inner::after {
    top: 0; }
  
  /*  Menu Trigger Vortex Animation */
  .rmp-menu-trigger-vortex .responsive-menu-pro-inner {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  
  .rmp-menu-trigger-vortex .responsive-menu-pro-inner::before, .rmp-menu-trigger-vortex .responsive-menu-pro-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  
  .rmp-menu-trigger-vortex .responsive-menu-pro-inner::before {
    transition-property: top, opacity; }
  
  .rmp-menu-trigger-vortex .responsive-menu-pro-inner::after {
    transition-property: bottom, transform; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-vortex.is-active:hover .responsive-menu-pro-inner, .rmp-menu-trigger-vortex.is-active .responsive-menu-pro-inner {
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  
  .rmp-menu-trigger-vortex.is-active .responsive-menu-pro-inner::before, .rmp-menu-trigger-vortex.is-active .responsive-menu-pro-inner::after {
    transition-delay: 0s; }
  
  .rmp-menu-trigger-vortex.is-active .responsive-menu-pro-inner::before {
    top: 0;
    opacity: 0; }
  
  .rmp-menu-trigger-vortex.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(90deg); }
  
  /*  Menu Trigger Vortex Reverse Animation */
  .rmp-menu-trigger-vortex-r .responsive-menu-pro-inner {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  
  .rmp-menu-trigger-vortex-r .responsive-menu-pro-inner::before, .rmp-menu-trigger-vortex-r .responsive-menu-pro-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  
  .rmp-menu-trigger-vortex-r .responsive-menu-pro-inner::before {
    transition-property: top, opacity; }
  
  .rmp-menu-trigger-vortex-r .responsive-menu-pro-inner::after {
    transition-property: bottom, transform; }
  
  .rmp-menu-trigger-vortex-r.is-active .responsive-menu-pro-inner {
    transform: rotate(-765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  
  .rmp-menu-trigger-vortex-r.is-active .responsive-menu-pro-inner::before, .rmp-menu-trigger-vortex-r.is-active .responsive-menu-pro-inner::after {
    transition-delay: 0s; }
  
  .rmp-menu-trigger-vortex-r.is-active .responsive-menu-pro-inner::before {
    top: 0;
    opacity: 0; }
  
  .rmp-menu-trigger-vortex-r.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }
  
  /*  Menu Trigger Stand  Animation */
  .rmp-menu-trigger-stand .responsive-menu-pro-inner {
    transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear; }
  
  .rmp-menu-trigger-stand .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-stand .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp_menu_trigger.rmp-menu-trigger-stand.is-active:hover .responsive-menu-pro-inner, .rmp_menu_trigger.rmp-menu-trigger-stand.is-active .responsive-menu-pro-inner {
    transform: rotate(90deg);
    background-color: transparent !important;
    transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear; }
  
  .rmp-menu-trigger-stand.is-active .responsive-menu-pro-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  .rmp-menu-trigger-stand.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger Stand Reverse Animation */
  .rmp-menu-trigger-stand-r .responsive-menu-pro-inner {
    transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear; }
  
  .rmp-menu-trigger-stand-r .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-stand-r .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-stand-r.is-active:hover .responsive-menu-pro-inner, .rmp-menu-trigger-stand-r.is-active .responsive-menu-pro-inner {
    transform: rotate(-90deg);
    background-color: transparent !important;
    transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear; }
  
  .rmp-menu-trigger-stand-r.is-active .responsive-menu-pro-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  .rmp-menu-trigger-stand-r.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger squeeze animation */
  .rmp-menu-trigger-squeeze .responsive-menu-pro-inner {
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-squeeze .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
  
  .rmp-menu-trigger-squeeze .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-squeeze.is-active .responsive-menu-pro-inner {
    transform: rotate(45deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  .rmp-menu-trigger-squeeze.is-active .responsive-menu-pro-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
  
  .rmp-menu-trigger-squeeze.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger spring animation */
  .rmp-menu-trigger-spring .responsive-menu-pro-inner {
    transition: background-color 0s 0.15s linear; }
  
  .rmp-menu-trigger-spring .responsive-menu-pro-inner::before {
    top: 8px;
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spring .responsive-menu-pro-inner::after {
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spring.is-active:hover .responsive-menu-pro-inner, .rmp-menu-trigger-spring.is-active .responsive-menu-pro-inner {
    transition-delay: 0.32s;
    background-color: transparent !important; }
  
  .rmp-menu-trigger-spring.is-active .responsive-menu-pro-inner::before {
    top: -8px;
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  
  .rmp-menu-trigger-spring.is-active .responsive-menu-pro-inner::after {
    top: -8px;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(-45deg); }
  
  /*  Menu Trigger spring reverse animation */
  .rmp-menu-trigger-spring-r .responsive-menu-pro-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.15s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spring-r .responsive-menu-pro-inner::before {
    top: 8px !important;
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  .rmp-menu-trigger-spring-r .responsive-menu-pro-inner::after {
    top: -8px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  
  .rmp-menu-trigger-spring-r.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(-45deg);
    transition-delay: 0.32s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  .rmp-menu-trigger-spring-r.is-active .responsive-menu-pro-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.32s linear; }
  
  .rmp-menu-trigger-spring-r.is-active .responsive-menu-pro-inner::before {
    top: 0 !important;
    transform: rotate(90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger slider animation */
  button.rmp_menu_trigger.rmp-menu-trigger-slider .responsive-menu-pro-inner {
    top: 0;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider .responsive-menu-pro-inner::before {
    top: 8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider .responsive-menu-pro-inner::after {
    top: -8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(45deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider.is-active .responsive-menu-pro-inner::before {
    transform: rotate(-45deg) translate3d(-3.5714285714px, -5px, 0);
    opacity: 0; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 8px, 0) rotate(-90deg); }
  
  /*  Menu Trigger slider reverse animation */
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-slider-r .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-slider-r .responsive-menu-pro-inner::after {
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r .responsive-menu-pro-inner {
    top: 0; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r .responsive-menu-pro-inner::before {
    top: 8px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r .responsive-menu-pro-inner::after {
    top: -8px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(-45deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r.is-active .responsive-menu-pro-inner::before {
    transform: rotate(45deg) translate3d(-3.5714285714px, -5px, 0);
    opacity: 0; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-slider-r.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 8px, 0) rotate(90deg); }
  
  /*  Menu Trigger emphatic animation */
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic {
    overflow: hidden; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic .responsive-menu-pro-inner {
    transition: background-color 0.2s 0.25s ease-in; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic .responsive-menu-pro-inner::before {
    left: 0;
    top: 8px;
    transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic .responsive-menu-pro-inner::after {
    top: -8px;
    right: 0;
    transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic.is-active:hover .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-emphatic.is-active .responsive-menu-pro-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic.is-active .responsive-menu-pro-inner::before {
    left: -50px;
    top: -50px;
    transform: translate3d(50px, 50px, 0) rotate(45deg);
    transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic.is-active .responsive-menu-pro-inner::after {
    right: -50px;
    top: -50px;
    transform: translate3d(-50px, 50px, 0) rotate(-45deg);
    transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
  
  /*  Menu Trigger emphatic reverese animation */
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r {
    overflow: hidden; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r .responsive-menu-pro-inner {
    transition: background-color 0.2s 0.25s ease-in; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r .responsive-menu-pro-inner::before {
    left: 0;
    top: 8px;
    transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r .responsive-menu-pro-inner::after {
    top: -8px;
    right: 0;
    transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in; }
  
  button.rmp_menu_trigger .rmp-menu-trigger-emphatic-r.is-active:hover .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r.is-active .responsive-menu-pro-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r.is-active .responsive-menu-pro-inner::before {
    left: -50px;
    top: 50px;
    transform: translate3d(50px, -50px, 0) rotate(-45deg);
    transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-emphatic-r.is-active .responsive-menu-pro-inner::after {
    right: -50px;
    top: 50px;
    transform: translate3d(-50px, -50px, 0) rotate(45deg);
    transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
  
  /*  Menu Trigger  elastic animation */
  button.rmp_menu_trigger.rmp-menu-trigger-elastic .responsive-menu-pro-inner {
    top: 0px;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic .responsive-menu-pro-inner::before {
    top: 8px;
    transition: opacity 0.15s 0.4s ease; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic .responsive-menu-pro-inner::after {
    top: -8px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(135deg);
    transition-delay: 0.1s; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic.is-active .responsive-menu-pro-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 8px, 0) rotate(-270deg);
    transition-delay: 0.1s; }
  
  /*  Menu Trigger  elastic reverse animation */
  button.rmp_menu_trigger.rmp-menu-trigger-elastic-r .responsive-menu-pro-inner {
    top: 0px;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic-r .responsive-menu-pro-inner::before {
    top: 8px;
    transition: opacity 0.15s 0.4s ease; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic-r .responsive-menu-pro-inner::after {
    top: -8px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic-r.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(-135deg);
    transition-delay: 0.1s; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic-r.is-active .responsive-menu-pro-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-elastic-r.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 8px, 0) rotate(270deg);
    transition-delay: 0.1s; }
  
  /*  Menu Trigger  collapse animation */
  button.rmp_menu_trigger.rmp-menu-trigger-collapse .responsive-menu-pro-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.15s;
    transition-delay: 0.15s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse .responsive-menu-pro-inner::after {
    top: -8px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse .responsive-menu-pro-inner::before {
    top: 8px;
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(-45deg);
    transition-delay: 0.32s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse.is-active .responsive-menu-pro-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse.is-active .responsive-menu-pro-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger  collapse reverse animation */
  button.rmp_menu_trigger.rmp-menu-trigger-collapse-r .responsive-menu-pro-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.15s;
    transition-delay: 0.15s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse-r .responsive-menu-pro-inner::after {
    top: -8px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse-r .responsive-menu-pro-inner::before {
    top: 8px;
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse-r.is-active .responsive-menu-pro-inner {
    transform: translate3d(0, 0px, 0) rotate(45deg);
    transition-delay: 0.32s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse-r.is-active .responsive-menu-pro-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-collapse-r.is-active .responsive-menu-pro-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1); }
  
  /*  Menu Trigger  arrowalt animation */
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt.is-active .responsive-menu-pro-inner::before {
    top: 0;
    transform: translate3d(-5px, -6.25px, 0) rotate(-45deg) scale(0.7, 1);
    transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: translate3d(-5px, 6.25px, 0) rotate(45deg) scale(0.7, 1);
    transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }
  
  /*  Menu Trigger  arrowalt reverse animation */
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt-r .responsive-menu-pro-inner::before {
    transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt-r .responsive-menu-pro-inner::after {
    transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt-r.is-active .responsive-menu-pro-inner::before {
    top: 0;
    transform: translate3d(5px, -6.25px, 0) rotate(45deg) scale(0.7, 1);
    transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrowalt-r.is-active .responsive-menu-pro-inner::after {
    bottom: 0;
    transform: translate3d(5px, 6.25px, 0) rotate(-45deg) scale(0.7, 1);
    transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }
  
  /*  Menu Trigger  arrow  animation */
  button.rmp_menu_trigger.rmp-menu-trigger-arrow .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-arrow .responsive-menu-pro-inner::after, button.rmp_menu_trigger.rmp-menu-trigger-arrow-r .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-arrow-r .responsive-menu-pro-inner::after {
    transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrow-r .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-arrow .responsive-menu-pro-inner::before {
    top: 8px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrow-r .responsive-menu-pro-inner::after, button.rmp_menu_trigger.rmp-menu-trigger-arrow .responsive-menu-pro-inner::after {
    top: -8px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrow.is-active .responsive-menu-pro-inner::before {
    transform: translate3d(-5px, -5px, 0) rotate(45deg) scale(0.7, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrow.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(-5px, 5px, 0) rotate(-45deg) scale(0.7, 1); }
  
  /*  Menu Trigger  arrow reverse  animation */
  button.rmp_menu_trigger.rmp-menu-trigger-arrow-r.is-active .responsive-menu-pro-inner::before {
    transform: translate3d(5px, -5px, 0) rotate(-45deg) scale(0.7, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-arrow-r.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(5px, 5px, 0) rotate(45deg) scale(0.7, 1); }
  
  /*  Menu Trigger  3dy  animation */
  button.rmp_menu_trigger.rmp-menu-trigger-3dy .responsive-menu-pro-box {
    perspective: 50px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dy .responsive-menu-pro-inner {
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  .rmp-menu-trigger-3dy .responsive-menu-pro-inner::before, .rmp-menu-trigger-3dy .responsive-menu-pro-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger .rmp-menu-trigger-3dy.is-active:hover .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-3dy.is-active .responsive-menu-pro-inner {
    background-color: transparent !important;
    transform: rotateX(-180deg); }
  
  button.rmp_menu_trigger .rmp-menu-trigger-3dy.is-active:hover .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-3dy.is-active .responsive-menu-pro-inner::before {
    transform: translate3d(0, -10px, 0) rotate(45deg); }
  
  button.rmp_menu_trigger .rmp-menu-trigger-3dy.is-active:hover .responsive-menu-pro-inner::after, button.rmp_menu_trigger.rmp-menu-trigger-3dy.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 10px, 0) rotate(-45deg); }
  
  /*  Menu Trigger  3dy  reverse animation */
  button.rmp_menu_trigger.rmp-menu-trigger-3dy-r .responsive-menu-pro-box {
    perspective: 50px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dy-r .responsive-menu-pro-inner {
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dy-r .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-3dy-r .responsive-menu-pro-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger .rmp-menu-trigger-3dy-r.is-active:hover .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-3dy-r.is-active .responsive-menu-pro-inner {
    background-color: transparent !important;
    transform: rotateX(180deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dy-r.is-active .responsive-menu-pro-inner::before {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dy-r.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  
  /*  Menu Trigger  3dx  animation */
  button.rmp_menu_trigger.rmp-menu-trigger-3dx .responsive-menu-pro-box {
    perspective: 50px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx .responsive-menu-pro-inner {
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-3dx .responsive-menu-pro-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx.is-active:hover .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-3dx.is-active .responsive-menu-pro-inner {
    background-color: transparent !important;
    transform: rotateY(180deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx.is-active .responsive-menu-pro-inner::before {
    transform: translate3d(0, -10px, 0) rotate(45deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 10px, 0) rotate(-45deg); }
  
  /*  Menu Trigger  3dx reverse  animation */
  button.rmp_menu_trigger.rmp-menu-trigger-3dx-r .responsive-menu-pro-box {
    perspective: 50px; }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx-r .responsive-menu-pro-inner {
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx-r .responsive-menu-pro-inner::before, button.rmp_menu_trigger.rmp-menu-trigger-3dx-r .responsive-menu-pro-inner:after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  
  button.rmp_menu_trigger .rmp-menu-trigger-3dx-r.is-active:hover .responsive-menu-pro-inner, button.rmp_menu_trigger.rmp-menu-trigger-3dx-r.is-active .responsive-menu-pro-inner {
    background-color: transparent !important;
    transform: rotateY(-180deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx-r.is-active .responsive-menu-pro-inner::before {
    transform: translate3d(0, -10px, 0) rotate(45deg); }
  
  button.rmp_menu_trigger.rmp-menu-trigger-3dx-r.is-active .responsive-menu-pro-inner::after {
    transform: translate3d(0, 10px, 0) rotate(-45deg); }
  
  @keyframes slideDown {
    0% {
      transform: translateY(-20px); }
    25% {
      transform: translateY(-18px); }
    50% {
      transform: translateY(-15px); }
    75% {
      transform: translateY(-12px); }
    100% {
      transform: translateY(0); } }
  @keyframes slideUp {
    0% {
      transform: translateY(10px); }
    25% {
      transform: translateY(8px); }
    50% {
      transform: translateY(7px); }
    75% {
      transform: translateY(5px); }
    100% {
      transform: translateY(0); } }
  @keyframes fade {
    0% {
      opacity: 0; }
    25% {
      opacity: 0.2; }
    50% {
      opacity: 0.3; }
    75% {
      opacity: 0.5; }
    100% {
      opacity: 1; } }
  @keyframes fadeUp {
    0% {
      transform: translateY(10px);
      opacity: 0; }
    25% {
      transform: translateY(8px);
      opacity: 0.25; }
    50% {
      transform: translateY(7px);
      opacity: 0.5; }
    75% {
      transform: translateY(5px);
      opacity: 0.75; }
    100% {
      transform: translateY(0);
      opacity: 1; } }
  /**
   This file contents common styling of menus.
   */
  html body {
    width: 100%; }
  
  .rmp-container {
    visibility: visible;
    padding: 0px 0px 0px 0px;
    z-index: 99998;
    transition: all 0.3s; }
    .rmp-container.rmp-slide-left, .rmp-container.rmp-push-left {
      transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%); }
    .rmp-container.rmp-slide-left.rmp-menu-open, .rmp-container.rmp-push-left.rmp-menu-open {
      transform: translateX(0);
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0); }
    .rmp-container.rmp-slide-right, .rmp-container.rmp-push-right {
      transform: translateX(100%);
      -ms-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%); }
    .rmp-container.rmp-slide-right.rmp-menu-open, .rmp-container.rmp-push-right.rmp-menu-open {
      transform: translateX(0);
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0); }
    .rmp-container.rmp-slide-top, .rmp-container.rmp-push-top {
      transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%); }
    .rmp-container.rmp-slide-top.rmp-menu-open, .rmp-container.rmp-push-top.rmp-menu-open {
      transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0); }
    .rmp-container.rmp-slide-bottom, .rmp-container.rmp-push-bottom {
      transform: translateY(100%);
      -ms-transform: translateY(100%);
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%); }
    .rmp-container.rmp-slide-bottom.rmp-menu-open, .rmp-container.rmp-push-bottom.rmp-menu-open {
      transform: translateX(0);
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      /** Scrolling bar in menu setting box **/ }
    .rmp-container::-webkit-scrollbar {
      width: 0px; }
    .rmp-container ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent; }
    .rmp-container ::-webkit-scrollbar-thumb {
      background: transparent; }
    .rmp-container ::-webkit-scrollbar-thumb:hover {
      background: transparent; }
    .rmp-container .rmp-menu-wrap .rmp-menu {
      transition: none;
      border-radius: 0;
      box-shadow: none;
      background: none;
      border: 0;
      bottom: auto;
      box-sizing: border-box;
      clip: auto;
      color: #666;
      display: block;
      float: none;
      font-family: inherit;
      font-size: 14px;
      height: auto;
      left: auto;
      line-height: 1.7;
      list-style-type: none;
      margin: 0;
      min-height: auto;
      max-height: none;
      opacity: 1;
      outline: none;
      overflow: visible;
      padding: 0;
      position: relative;
      pointer-events: auto;
      right: auto;
      text-align: left;
      text-decoration: none;
      text-indent: 0;
      text-transform: none;
      transform: none;
      top: auto;
      visibility: inherit;
      width: auto;
      word-wrap: break-word;
      white-space: normal; }
  
  button.rmp_menu_trigger {
    z-index: 999999;
    overflow: hidden;
    outline: none;
    border: 0;
    margin: 0;
    display: inline-block;
    transition: transform 0.5s, background-color 0.5s;
    padding: 0; }
    button.rmp_menu_trigger .responsive-menu-pro-inner::before, button.rmp_menu_trigger .responsive-menu-pro-inner::after {
      content: "";
      display: block; }
    button.rmp_menu_trigger .responsive-menu-pro-inner::before {
      top: 10px; }
    button.rmp_menu_trigger .responsive-menu-pro-inner::after {
      bottom: 10px; }
    button.rmp_menu_trigger .rmp-trigger-box {
      width: 40px;
      display: inline-block;
      position: relative;
      pointer-events: none;
      vertical-align: super; }
  
  .rmp-mega-menu-container .rmp-menu-sub-level-item {
    display: none; }
  .rmp-mega-menu-container .rmp-mega-menu-row {
    display: flex; }
  .rmp-mega-menu-container .rmp-mega-menu-col-12 {
    width: 100%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-11 {
    width: 91.66666667%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-10 {
    width: 83.33333333%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-9 {
    width: 75%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-8 {
    width: 66.66666667%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-7 {
    width: 58.33333333%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-6 {
    width: 50%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-5 {
    width: 41.66666667%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-4 {
    width: 33.33333333%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-3 {
    width: 25%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-2 {
    width: 16.66666667%; }
  .rmp-mega-menu-container .rmp-mega-menu-col-1 {
    width: 8.33333333%; }
  
  /* Header style */
  .rmp-header-bar-container {
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99997;
    display: block;
    transition: all 0.3s; }
    .rmp-header-bar-container .rmp-header-bar-items {
      height: 100%;
      display: flex;
      align-items: center; }
      .rmp-header-bar-container .rmp-header-bar-items .rmp-header-bar-contents {
        display: inline; }
    .rmp-header-bar-container .rmp-header-bar-item {
      display: inline-block; }
  
  .rmp-page-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0.5s linear, opacity 0.5s linear, background-color 0.5s ease;
    z-index: 9; }
  
  .rmp-widget {
    margin-bottom: 10px; }
    .rmp-widget input.search-field {
      max-width: 100%; }
    .rmp-widget .wp-calendar-table {
      min-width: 100%; }
    .rmp-widget table td, .rmp-widget table th, .rmp-widget .wp-block-table td, .rmp-widget .wp-block-table th {
      padding: 2px; }
.apply-form-wrap {
    padding: 60px 0 30px;
}

.desktop-form,
.mobile-form {
    text-align: center;
}

.desktop-form {
    display: block;
}

.gform_page_fields {
    max-width: 600px;
    margin: 0 auto;
}

.gf_progressbar_wrapper {
    display: none;
}

h1.page-title,
h2.page-two-title {
    font-size: 40px;
    font-size: 4rem;
}

#gform_254 .title-list-block .bright-blue,
#gform_256 .title-list-block .bright-blue {
    background: #F5F0EB;
    display: table;
    margin: 0 auto 20px;
    padding: 0 10px;
}

ul.get-started-list {
    margin-left: 0;
    color: #525666;
    text-align: left;
    max-width: 365px;
    margin: 32px auto 32px !important;
}

ul.get-started-list li {
    margin: 0;
    padding: 0 0 16px 24px;
    list-style: none;
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2022/08/check-light-blue-small.svg) no-repeat;
    background-position: 0 7px;
}

ul.get-started-list li:last-of-type {
    padding-bottom: 0;
}

.desktop-form .ginput_container_select {
    width: 100%;
    max-width: 340px !important;
    max-width: 425px !important;
    max-width: 363px !important;
    margin: 0 auto;
}

.gform_page_footer {
    margin-top: 30px;
    margin-top: 20px;
}

.gform_page_footer {
    /* margin-top: 50px; */
}

.apply-form-wrap .gform_previous_button {
    display: none;
}

.apply-form-wrap .page-two ul.gform_fields li.gfield:not(.html-title-block-two) {
    max-width: 410px;
    margin: 25px auto 0 !important;
}

.apply-form-wrap .page-two ul.gform_fields li.gfield.gform_hidden,
.apply-form-wrap .page-two ul.gform_fields li.gfield.tcpa-field {
    margin: 0 !important;
}

.apply-form-wrap .form-disclaimer-wrap {
    color: #75757C;
    font-size: 18px;
    font-size: 1.8rem;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    max-width: 846px;
    margin: 20px auto 0;
}

.apply-form-wrap .form-disclaimer-wrap p {
    margin-bottom: 0;
}


li.button-styled-radios {
    border: 1px solid #7d8396;
    border-radius: 25px;
    overflow: hidden;
}

li.button-styled-radios .gfield_label {
    background: #f1f3f9;
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 10px;
}

li.button-styled-radios .gfield_radio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px -8px 10px;
    padding: 0 20px;
}

li.button-styled-radios .gfield_radio .gchoice {
    margin: 0 8px 15px;
    width: 30%;
}

li.button-styled-radios .gfield_radio .gchoice label {
    font-family: 'Avenir Next Demi';
    background-color: #006CD1;
    color: #fff;
    cursor: pointer;
    padding: 15px 35px;
    width: auto;
    border-radius: 15px;
    line-height: 1.2;
    display: block;
}

div.gform_wrapper li.button-styled-radios ul.gfield_radio input[type=radio] {
    display: block;
    width: 1px;
    height: 1px;
    position: absolute;
    left: -99999px;
}

.apply-loan-horizontal .gform_page_fields {
    max-width: 100%;
}

.apply-loan-horizontal .gform_page_fields li.gfield:not(.button-styled-radios) {
    max-width: 600px;
    margin: 0 auto;
}

.apply-loan-vertical li.button-styled-radios .gfield_radio .gchoice {
    width: 100%;
}

.apply-loan-vertical li.button-styled-radios {
    max-width: 480px;
    margin: 0 auto;
}

.apply-loan-bullets ul.get-started-list {
    padding-left: 15px;
}

/* .site-header .wrap {
                  padding-top: 15px;
                  padding-bottom: 15px;
                } */

.site-header {
    padding: 15px 0;
    padding: 8px 0;
}

.apply-buttons .gform_page_fields {
    max-width: 910px;
}

.apply-buttons li.button-styled-radios .gfield_radio .gchoice label {
    font-size: 20px;
    font-size: 2rem;
    border-radius: 40px;
}

.apply-buttons li.button-styled-radios .gfield_radio .gchoice {
    margin: 0 8px 16px;
    width: calc(33.33% - 25px);
}

.apply-buttons li.button-styled-radios .gfield_label {
    font-size: 20px;
    font-size: 2rem;
    background: #DFE4F1;
    padding: 15px;
}

.apply-buttons li.button-styled-radios .gfield_radio {
    margin: 32px -8px 16px;
}

.gform_page_footer:after {
    content: '';
    display: block;
    width: 380px;
    height: 185px;
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2023/12/Group-1342.svg);
    position: absolute;
    top: -18px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 100%;
    margin-left: -60px;
}

.gform_page_footer {
    position: relative;
    padding-bottom: 50px;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.gform_page_footer button {
    position: relative;
    z-index: 1;
    width: auto;
}

.gform_page_fields {
    position: relative;
    z-index: 1;
}

.gform_page_footer:after {
    content: none;
}

.gform_page_footer {
    padding-bottom: 0;
    margin: 0;
}

#validation_message_108_27,
#validation_message_108_62,
#validation_message_221_68,
#validation_message_268_78,
#validation_message_269_76,
#validation_message_254_64,
#validation_message_256_64,
#validation_message_262_62,
#validation_message_271_76,
#validation_message_274_76,
#validation_message_281_76,
#validation_message_282_76,
#validation_message_283_76,
#validation_message_275_76,
#validation_message_276_76,
#validation_message_272_78,
#validation_message_242_65,
#validation_message_244_64,
#validation_message_277_78,
#validation_message_280_76 {
    display: block;
    background: #ffdfdf;
    color: #c7364a;
    transform: translateY(5px);
    padding: 3px 10px;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    border-radius: 5px;
    font-weight: 500;
}

#validation_message_108_27 img,
#validation_message_108_62 img,
#validation_message_221_68 img,
#validation_message_268_78 img,
#validation_message_269_76 img,
#validation_message_254_64 img,
#validation_message_256_64 img,
#validation_message_262_62 img,
#validation_message_271_76 img,
#validation_message_274_76 img,
#validation_message_282_76 img,
#validation_message_283_76 img,
#validation_message_281_76 img,
#validation_message_275_76 img,
#validation_message_276_76 img,
#validation_message_272_78 img,
#validation_message_242_65 img,
#validation_message_244_64 img,
#validation_message_277_78 img,
#validation_message_280_76 img {
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

@media only screen and (max-width: 1023px) {
    .apply-form-wrap {
        padding: 50px 0 30px;
    }

    /* .site-header {
                      padding: 0;
                    } */
    .apply-form-wrap {
        padding: 40px 0 25px;
    }

    .gform_page_footer:after {
        width: 341px;
        height: 169px;
    }
}

@media only screen and (max-width: 900px) {
    li.button-styled-radios .gfield_radio .gchoice {
        width: 100%;
    }

    .apply-buttons li.button-styled-radios .gfield_radio .gchoice {
        width: 100%;
    }

    .gform_page_footer:after {
        width: 235px;
        height: 121px;
        top: -25px;
        margin-left: -20px;
    }
}

@media only screen and (max-width: 767px) {

    /* .apply-form-wrap .desktop-form {
                        display: none;
                    }
                    .apply-form-wrap .mobile-form {
                        display: block;
                    } */
    .desktop-form h1.page-title,
    .desktop-form h2.page-two-title {
        font-size: 32px;
        font-size: 3.2rem;
    }

    .desktop-form h2.page-two-title {
        font-size: 42px;
        font-size: 4.2rem;
    }

    .desktop-form .get-started-list {
        font-size: 16px;
        font-size: 1.6rem;
    }

    /* ul.get-started-list li {
                        background-position: 0 0;
                    } */
    .desktop-form .page-one .ginput_container_select {
        max-width: 340px;
        margin: 0 auto;
    }

    .gform_page_footer {
        /* margin-top: 45px; */
    }

    .gform_page_footer .button {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 739px) {

    .desktop-form h1.page-title,
    .desktop-form h2.page-two-title {
        font-size: 27px;
        font-size: 2.7rem;
    }

    body.sticky-footer-form {
        padding-bottom: 96px;
    }

    .gform_page_footer {
        display: block;
        padding-bottom: 100px;
    }

    .gform_page_footer:after {
        top: auto;
        background-image: url(https://www.nationaldebtrelief.com/wp-content/uploads/2023/12/Group-1343.svg);
        width: 100%;
        height: 80px;
        left: 10px;
        bottom: 10px;
    }

    .gform_page_footer button {
        width: 100%;
        max-width: unset;
    }
}

@media only screen and (max-width: 479px) {
    /* .desktop-form h1.page-title,
                    .desktop-form h2.page-two-title {
                        font-size: 30px;
                        font-size: 3rem;
                    } */
    /* .desktop-form .get-started-list {
                        font-size: 18px;
                        font-size: 1.8rem;
                    } */
    /* .desktop-form select {
                        font-size: 15px;
                        font-size: 1.5rem;
                        padding-left: 12px!important;
                    } */
}

@media only screen and (max-width: 374px) {

    body.sticky-footer-form {
        padding-bottom: 148px;
    }
}


.site-header {
    box-shadow: none;
    border-bottom: 1px solid #eee;
}

.site-header .wrap,
.breadcrumb .wrap,
.top-bar .wrap,
.additional-footer .wrap {
    padding: 0 4%;
}

.site-header .button.secondary-alt {
    border: 1px solid #01014f;
    padding: 11px 22px 11px 22px;
    display: block;
}

.site-header .button.secondary-alt span {
    font-weight: 800;
}

.hide-menu .site-inner {
    padding-top: 105px;
}

h1.page-title,
h2.page-two-title {
    font-size: 42px;
    font-size: 4.2rem;
    font-weight: 500;
    font-family: 'Avenir Next', sans-serif;
    line-height: 1.35;
}

ul.get-started-list {
    max-width: 425px;
    margin: 39px auto 40px !important;
    font-size: 21px;
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 1.75;
}

ul.get-started-list li {
    padding: 0 0 6px 35px;
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/09/check-light-blue.svg) no-repeat;
    background-position: 0 8px;
    background-size: 22px;
}

.desktop-form .ginput_container_select {
    max-width: 340px !important;
    margin: 0 auto;
}

.gform_wrapper .top_label .debt-dropdown .ginput_container_select select {
    background-color: #ffffff;
    background-image: none;
    border: 2px solid #00004d;
    padding: 0 0 0 25px;
    font-size: 17px;
    font-size: 1.7rem;
    font-weight: 500;
    box-shadow: none;
    margin-bottom: 0;
}

.gform_wrapper .debt-dropdown .ginput_container_select:after {
    height: 42px;
    top: 10px;
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/09/caret-down-solid-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.desktop-form .gform_page_footer {
    margin-top: 30px;
}

.apply-form-wrap div.gform_wrapper button {
    padding: 11px 35px;
    background: #006CD1;
    border-color: #006CD1;
    box-shadow: 0 20px 20px -10px rgba(0, 119, 227, 0.2);
}

body .gform_wrapper .gform_fields {
    margin-top: 0;
}

.hjs-section {
    padding: 50px 0;
}

.hjs-section h1.section-title,
.hjs-section h2.section-title,
.hjs-section h2 {
    font-size: 42px;
    font-size: 4.2rem;
    letter-spacing: 0;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 500;
    line-height: 1.35;
}

.hjs-section .single-column {
    margin-top: 5.2%;
}

.overall-rating {
    display: flex !important;
}

.content-sidebar-wrap,
.wrap,
main.tribe-events-pg-template,
main#tribe-events-pg-template {
    margin: 0 auto;
    max-width: 960px;
}

.star-rating-wrap {
    max-width: 612px;
}

.rating-sources-wrap .rating-source {
    padding: 0;
    background: transparent;
    border-radius: 0;
    min-width: unset;
    height: auto;
}

span.rating-text {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.75;
}

.rating-sources-wrap {
    margin-top: 22px;
}

.rating-source .source-rating {
    width: 134px;
}

.hjs-section.light-blue-title .section-title {
    max-width: 875px;
    margin: 0 auto;
    font-style: italic;
    font-size: 54px;
    font-size: 5.4rem;
}

.additional-footer {
    display: none;
}

.apply-b2b .additional-footer,
.apply-loan-b2b .additional-footer {
    display: block;
    padding: 10px 0 70px;
    font-weight: 500;
    font-size: 21px;
    font-size: 2.1rem;
}

.additional-footer .right {
    font-weight: 600;
}

.additional-footer .right a svg {
    transform: rotate(90deg);
}

.additional-footer .footer-bottom {
    display: none;
}

.apply-b2b .site-footer,
.apply-loan-b2b .site-footer {
    color: #7b7ba3;
    background-color: #000052;
}

.footer-widgets {
    background-color: #f1f3f9;
    line-height: 1.4;
    padding: 65px 0 25px;
}

.footer-widgets .social-links ul li a:hover,
.footer-widgets .social-links ul li a:focus {
    color: #fff;
}

.site-footer {
    background: #f1f3f9;
    color: #00004e;
    padding: 25px 0 50px;
}

.footer-widgets-1 {
    display: none;
}

.footer-widgets .widget-title {
    display: none;
}

.footer-widgets .menu li,
.footer-widgets .menu li a {
    font-family: 'Avenir Next', sans-serif;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
}

.footer-widgets a {
    color: #00004e;
    font-weight: 600;
}

.footer-widgets .widget ul>li {
    margin-bottom: 22px;
}

.footer-widgets a.bbb-form,
.footer-widgets .reach-finanacial {
    display: none;
}

.footer-widgets a.bbb-form.only-on-apply {
    display: block !important;
}

.footer-widgets-5 .social-links {
    display: block;
}

.site-footer p.copyright {
    font-family: 'Avenir Next', sans-serif;
    font-weight: 600;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.75;
}

.site-footer .disclaimer {
    line-height: 1.4;
    font-weight: 500;
}

.footer-widgets .terms,
.footer-widgets .account {
    display: none;
}

.footer-widgets .only-on-apply.menu-item {
    display: list-item !important;
}

.footer-widgets-4 .menu {
    display: flex;
    flex-direction: column;
}

.footer-widgets-4 .menu .faqs {
    order: 3;
    margin-bottom: 22px !important;
}

.footer-widgets-4 .menu .sitemap {
    order: 4;
    margin-bottom: 0;
}

.footer-widgets .social-links ul li a {
    width: 40px;
    height: 40px;
    background: #00004e;
    color: #fff;
}

.footer-widgets .social-links ul li a svg {
    fill: #fff;
}

.footer-widgets .social-links ul li {
    margin: 0 4.5px;
}

.footer-widgets .social-links ul li:first-child {
    margin-left: 0;
}

.footer-widgets .social-links ul li:last-child {
    margin-right: 0;
}

.footer-widgets-1,
.footer-widgets-2,
.footer-widgets-3,
.footer-widgets-4,
.footer-widgets-5 {
    width: 17.948%;
    margin-left: 2.5%;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}

.footer-widgets-5 {
    width: 38.55%;
    order: 5;
}

.footer-widgets-2 {
    margin-left: 0;
}

.footer-widgets-5 .widget .custom-html-widget {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
}

.footer-widgets-5 .social-links {
    margin-top: auto;
    width: 100%;
    padding-top: 25px;
}

.footer-widgets a.bbb-form img {
    display: block;
    width: 130px;
}

.footer-widgets-5 .widget,
.footer-widgets-5 .widget .widget-wrap {
    height: 100%;
}

.footer-widgets .social-links ul {
    text-align: right;
}

.site-header.scrolling {
    padding: 3px 0 7px;
}

.footer-widgets .wrap,
.site-footer .wrap {
    padding: 0;
}

.site-title a,
.site-title a:focus,
.site-title a:hover {
    width: 114px;
    height: 90px;
}

/* .site-header .button.secondary-alt {
    padding: 10px 19px;
} */
.site-header .phone .mobile-text {
    background-size: 21px;
    padding-left: 30px;
    line-height: 22px;
}

.site-header .phone:hover .mobile-text,
.site-header .phone:focus .mobile-text {
    background-image: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/11/phone_icon_white.png);
}

.apply-loan-bullets ul.get-started-list,
.apply-loan ul.get-started-list {
    padding-left: 15px;
}

.hjs-section.bigger-title h2.section-title,
.hjs-section.bigger-title h2 {
    line-height: 1.35;
}

#gform_254 .title-list-block .bright-blue,
#gform_256 .title-list-block .bright-blue {
    font-size: 21px;
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 1.75;
}

.hjs-bottom-logos a {
    padding: 0;
}

input,
select,
textarea,
div.gform_wrapper textarea.small,
div.gform_wrapper textarea.medium,
div.gform_wrapper textarea.large,
div.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    background-color: #fff;
    color: #000052;
    border-radius: 0;
    font-size: 17px;
    font-size: 1.7rem;
    padding: 29px 16px 7px;
    width: 100%;
    border: 1px solid #717684;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 500;
}

.apply-form-wrap .page-two ul.gform_fields li.gfield:not(.html-title-block-two) {
    max-width: 315px;
    margin: 20px auto 0 !important;
}

.gfield_required {
    display: none;
}

input[placeholder]~label,
textarea[placeholder]~label,
.iti~label {
    transition: transform .25s, opacity .25s ease-in-out;
    transform-origin: 0 0;
    opacity: .5;
    color: #717684;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 500;
    pointer-events: none;
}

.iti__flag-container {
    display: none;
}

.iti>input {
    padding-left: 16px !important;
}

.iti:focus-within~label,
.iti:has(input:first-of-type:not(:placeholder-shown))~label {
    font-size: 12px;
    align-items: flex-start;
    padding-top: 8px;
    color: #717684;
    padding: 8px 0 0 16px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    opacity: 0.5;
}

/*input[placeholder] ~ label, textarea[placeholder] ~ label {
    transition: transform .25s,opacity .25s ease-in-out;
    transform-origin: 0 0;
    opacity: .5;
    color: #717684;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    user-select: none;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 500;
    pointer-events: none;
} */

.apply-form-wrap .form-disclaimer-wrap {
    color: #525666;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.4;
    margin-top: 5px;
    max-width: 960px;
    font-weight: 500;
}

.page:not(.wide-page) .content-sidebar-wrap {
    max-width: 960px;
}

div.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):focus,
div.gform_wrapper textarea:focus {
    border: 1px solid #00004e !important;
    outline: none;
    border: 1px solid #717684;
    color: #000052;
}

input[placeholder]:focus~label,
input[placeholder]:not(:placeholder-shown)~label,
textarea[placeholder]:focus~label,
textarea[placeholder]:not(:placeholder-shown)~label {
    font-size: 12px;
    align-items: flex-start;
    padding-top: 8px;
    color: #717684;
    padding: 8px 0 0 16px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    opacity: 0.5;
}

input[placeholder]:focus~label .gfield_required,
input[placeholder]:not(:placeholder-shown)~label .gfield_required,
textarea[placeholder]:focus~label .gfield_required,
textarea[placeholder]:not(:placeholder-shown)~label .gfield_required {
    display: none;
}

.apply-loan-st.hide-menu .title-area {
    margin: 0 auto 0 0;
}

.apply-loan-st .site-title a,
.apply-loan-st .site-title a:focus,
.apply-loan-st .site-title a:hover {
    margin-left: 0;
}

.apply-loan-st ul.badges {
    display: flex;
    column-gap: 32px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.apply-loan-st.apply-st2 ul.badges {
    margin: 30px 0 0;
}

.apply-loan-st ul.badges li {
    list-style-type: none;
}

/* .apply-loan-st .apply-loan-st .desktop-form .ginput_container_select {
    max-width: 410px!important;
} */
.apply-loan-st.hide-menu .menu-wrap {
    display: none;
}

.apply-loan-st ul.get-started-list {
    margin: 32px auto !important;
    width: 375px;
    width: 440px;
    max-width: 100%;
}

.apply-loan-st .desktop-form .ginput_container_select {
    max-width: 410px !important;
}

.apply-loan-st .hjs-section>.wrap {
    max-width: 1280px;
}

.apply-loan-st .columns-icons .title-content-wrap {
    padding: 0 20px;
}

.apply-loan-st .accordion-simple .accordion-blocks .tab-content ul li,
.apply-loan-st .faq-container .tab-content ul li {
    list-style: disc;
}

#narrow-cta {
    margin: 0 auto;
    max-width: 846px;
    border-radius: 32px;
    padding: 32px;
    margin-bottom: 100px;
}

#narrow-cta .section-description {
    margin-top: 0;
}

#narrow-cta .title-wrap p img {
    display: block;
    margin: 0 auto;
}

#narrow-cta h2 {
    margin-bottom: 32px;
}

#narrow-cta .hjs-buttons-group li:nth-of-type(2) a img {
    width: 16px;
    position: relative;
    top: 2px;
    margin-right: 4px;
}

#narrow-cta .hjs-buttons-group li:nth-of-type(1) {
    display: none;
}

.apply-loan-st .testimonial-cards-alt .wrap {
    max-width: 100%;
}

.apply-loan-st .call-to-action .hjs-bottom-logos {
    justify-content: center;
    column-gap: 30px;
}

.apply-loan-st .footer-center-logo {
    display: block;
    margin: 0 auto 32px;
}

.apply-loan-st .site-footer {
    background: #F5F0EB;
    padding-top: 48px;
}

.apply-form-wrap.page-2~*,
.apply-form-wrap.page-2 a.disclaimer-anchor {
    display: none;
}

.footer-privacy-menu {
    margin: 32px 0;
}

.footer-privacy-menu ul {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    flex-wrap: wrap;
    font-size: 14px;
    font-size: 1.4rem;
}

.footer-privacy-menu ul a {
    text-decoration: underline;
}

.apply-loan-st .additional-footer {
    display: block;
    background: #1A1B24;
    color: #fff;
}

.apply-loan-st .additional-footer a {
    color: #fff;
}

.apply-loan-st .additional-footer a[href*='tel'] img {
    width: 17px;
    margin-right: 4px
}

.apply-loan-st .additional-footer .right {
    font-weight: 400;
}

.desktop-form.sticky .gform_wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: #1F2147;
    opacity: 0;
}

.desktop-form.sticky .gform_page_fields {
    flex-grow: 1;
    margin-right: 16px;
}

.desktop-form.sticky .gform_page.page-one {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.desktop-form.sticky .gform_wrapper ul.gform_fields li.gfield {
    margin: 0;
}

.desktop-form.sticky .gform_wrapper .debt-dropdown .ginput_container_select:after {
    border: 0;
}

.apply-loan-st .gform_page_footer {
    margin-top: 10px;
}

.apply-loan-st .gform_page_footer .button {
    margin-bottom: 0;
    padding: 11px 13px;
}

.apply-loan-st ul.badges li img {
    width: 120px;
    max-height: 120px;
}

a.disclaimer-anchor {
    color: #006CE3;
    font-family: 'Avenir Next Demi', sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
}

.apply-loan-st .gform_next_button {
    width: 100%;
    max-width: 410px;
}

@media only screen and (max-width: 1023px) {
    .apply-form-wrap {
        padding: 50px 0 30px;
    }

    .hjs-section.no-gutters .wrap,
    .footer-widgets .wrap,
    .site-footer .wrap {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media only screen and (max-width: 960px) {
    .site-header.scrolling {
        padding: 7px 0;
    }
}

@media only screen and (max-width: 900px) {

    .hjs-section .single-column,
    .hjs-section .one-half,
    .hjs-section .one-third,
    .hjs-section .two-thirds,
    .hjs-section .one-fourth,
    .hjs-section .one-fifth,
    .hjs-section .one-sixth {
        margin-top: 50px;
    }

    .hjs-section.light-blue-title .section-title,
    .hjs-section.bigger-title h2.section-title,
    .hjs-section.bigger-title h2 {
        font-size: 36px;
        font-size: 3.6rem;
    }

    .footer-widgets {
        position: relative;
        padding-top: 140px;
    }

    .footer-widgets .bbb-mobile-logo.only-on-apply {
        display: block !important;
    }

    .footer-widgets .bbb-mobile-logo {
        display: block;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .footer-widgets .bbb-mobile-logo .bbb-form {
        display: block;
    }

    .footer-widgets a.bbb-form img {
        margin: 0 auto;
    }

    .footer-widgets-1,
    .footer-widgets-2,
    .footer-widgets-3,
    .footer-widgets-4,
    .footer-widgets-5 {
        width: 48.5%;
    }

    .footer-widgets-4 {
        margin: 25px 0 0;
    }

    .footer-widgets-5 {
        width: 100%;
        margin: 40px 0 0;
    }

    .footer-widgets .widget:not(.bbb-mobile-logo) .custom-html-widget a.bbb-form {
        display: none !important;
    }

    .footer-widgets-5 .social-links {
        padding-top: 0;
    }

    .footer-widgets .social-links ul {
        text-align: center;
    }

    .footer-widgets .social-links ul li {
        margin: 0 5.5px 15px;
    }
}

@media only screen and (max-width: 767px) {

    .desktop-form h1.page-title,
    .desktop-form h2.page-two-title {
        font-size: 36px;
        font-size: 3.6rem;
    }

    .desktop-form .get-started-list {
        font-size: 21px;
        font-size: 2.1rem;
    }

    .desktop-form .gform_page_footer {
        margin-top: 45px;
    }

    .desktop-form .gform_page_footer .button {
        margin-bottom: 30px;
    }

    .site-header .phone .desktop-text {
        display: none;
    }

    .site-header .phone .mobile-text {
        display: block;
    }

    #narrow-cta .hjs-buttons-group li:nth-of-type(1) {
        display: inline-block;
    }

    #narrow-cta .hjs-buttons-group li:nth-of-type(2) {
        display: none;
    }

    .apply-loan-st .apply-form-wrap {
        padding: 16px 0;
    }

    .apply-loan-st .desktop-form .page-title {
        margin-bottom: 16px;
    }

    .apply-loan-st ul.badges {
        margin-bottom: 16px;
    }

    .apply-loan-st ul.get-started-list {
        margin: 16px auto !important;
    }

    .apply-loan-st .desktop-form .gform_page_footer {
        margin-top: 16px;
    }

    .apply-loan-st .desktop-form .gform_page_footer .button {
        margin-bottom: 0;
    }

    #narrow-cta {
        padding: 16px;
        margin-bottom: 32px;
        text-align: left;
    }

    #narrow-cta .section-description {
        text-align: left;
    }

    #narrow-cta .title-wrap p img {
        margin-left: 0;
    }

    #narrow-cta .hjs-buttons-group ul {
        justify-content: flex-start;
    }

    .apply-loan-st .testimonial-cards-alt .section-title {
        font-size: 24px;
        font-size: 2.4rem;
    }

    .testimonial-cards-alt .testimonial-slider-wrap.no-slider {
        margin-top: 40px !important;
    }
}

@media only screen and (max-width: 739px) {

    .site-header .wrap,
    .breadcrumb .wrap {
        padding: 0 6%;
    }

    .site-header .button.secondary-alt {
        padding: 10px 14px;
    }

    .page:not(.wide-page) .content-sidebar-wrap,
    .wrap,
    .hjs-section.no-gutters .wrap,
    .footer-widgets .wrap,
    .site-footer .wrap {
        padding-left: 6%;
        padding-right: 6%;
    }

    button.button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        font-size: 18px;
        font-size: 1.8rem;
    }

    .hjs-section {
        padding: 40px 0;
    }

    .hjs-section h1.section-title,
    .hjs-section h2.section-title,
    .hjs-section h2 {
        max-width: 100%;
        font-size: 36px;
        font-size: 3.6rem;
        background: none !important;
    }

    .apply-b2b .additional-footer,
    .apply-loan-b2b .additional-footer,
    #gform_254 .title-list-block .bright-blue,
    #gform_256 .title-list-block .bright-blue {
        font-size: 18px;
        font-size: 1.8rem;
    }

    .desktop-form.sticky .gform_wrapper {
        opacity: 1;
    }

    .apply-loan-st .site-title a,
    .apply-loan-st .site-title a:focus,
    .apply-loan-st .site-title a:hover {
        width: 65px;
        height: 51px;
    }

    .apply-loan-st .site-header p.phone a.button {
        padding: 5px 20px;
    }

    .apply-loan-st .site-header .phone .mobile-text {
        background-size: 16px !important;
    }

    .apply-loan-st.hide-menu .site-header .widget-area {
        width: auto;
    }

    .apply-loan-st #narrow-cta h2 {
        font-size: 2.8rem;
    }
}

@media only screen and (max-width: 660px) {
    .site-header p.phone a.button {
        padding: 10px 16px 10px 13px;
    }
}

@media only screen and (max-width: 600px) {

    .hjs-section.has-title .single-column:first-of-type,
    .hjs-section .one-half,
    .hjs-section .one-third,
    .hjs-section .two-thirds,
    .hjs-section .one-fourth,
    .hjs-section .one-fifth,
    .hjs-section .one-sixth {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 479px) {

    .page:not(.wide-page) .content-sidebar-wrap,
    .wrap,
    .hjs-section.no-gutters .wrap,
    .footer-widgets .wrap,
    .site-footer .wrap {
        padding-left: 5%;
        padding-right: 5%;
    }

    .desktop-form .gform_wrapper .top_label .debt-dropdown .ginput_container_select select {
        font-size: 15px;
        font-size: 1.5rem;
        padding-left: 12px !important;
    }

    .desktop-form .get-started-list {
        font-size: 18px;
        font-size: 1.8rem;
    }

    .desktop-form h1.page-title,
    .desktop-form h2.page-two-title {
        font-size: 30px;
        font-size: 3rem;
    }

    .site-header p.phone a.button {
        font-size: 15px;
        font-size: 1.5rem;
    }

    .site-header .phone .mobile-text {
        background-size: 17px;
        padding-left: 22px;
    }
}

@media only screen and (max-width: 374px) {
    .site-header p.phone a.button {
        padding: 4px 10px 4px 7px;
        font-size: 10px;
        font-size: 1rem;
    }

    .site-header .phone .mobile-text {
        background-size: 15px;
        padding-left: 18px;
    }

    .desktop-form.sticky .gform_page.page-one {
        display: block;
    }

    .desktop-form.sticky .gform_page_fields {
        margin-right: 0;
    }
}