body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  color: #ffffff;
}
.question {
  /* text-align: center; */
  font-size: 18px;
  font-weight: 600;
  color: #25408F;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 0; /* This removes extra space between elements */
}

.checkbox-container .MuiFormControlLabel-root {
  margin: 0; /* Removes any additional margins applied by default */
}
.social-icon {
  width: 35px;
  height: 35px;
  padding: 1px 9px 14px 4px;
}

.partner-logo img{
  width: 10%;
}

@media(max-width:500px) {
  .partner-logo img{
    width: 30%;
  }
  
  .debt-checks {
    width: 100% !important;
    margin: 1px;
  }

  .tab-content {
    /* padding: 0 1px 22px 3px !important; */
  }

  .custom-btn::after {
    top: auto;
    background-image: url(https://www.nationaldebtrelief.com/wp-content/uploads/2023/12/Group-1343.svg);
    width: 100%;
    height: 80px;
    left: 10px;
    bottom: 10px;
  }
}

.red-border {
  border: 1px solid red !important;
}

.debt-checks {
  width: 50%;
  margin: auto;
}

.tab-content {
  margin-top: 20px;
  max-height: 999em;
  /* padding: 0 14px 22px 46px; */
  border-top: 0;
  border-radius: 0 0 8px 8px;
}

.accordion-collapse.show {
  border-bottom: 2px solid #0074e0 !important;
  border-left: 2px solid #0074e0 !important;
  border-right: 2px solid #0074e0 !important;

  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.tab-content p {
  text-align: left;
}

.tab-content ul {
  width: 80%;
}

.tab-content ul li {
  list-style: circle;
  text-align: left;
}

.custom-btn::after {
  content: '';
  display: block;
  width: 380px;
  height: 185px;
  background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2023/12/Group-1342.svg);
  position: absolute;
  top: -65px;
  background-repeat: no-repeat;
  background-size: contain;
  left: 100%;
  margin-left: -40px;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.pdr-label {
  background: transparent;
}

.pdr-input:focus+.pdr-input:not(:placeholder-shown)+label {
  transform: translateY(-100%) scale(0.8);
}

.pdr-input {
  width: 100%;
  height: 65px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  color: #0077e7 !important;
  font-size: 1.8rem !important;
}

.ginput_container_select select {
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #00004d;
  padding: 0 0 0 25px;
  font-size: 17px;
  font-size: 1.7rem;
  font-weight: 500;
  box-shadow: none;
  margin-bottom: 0;
}

.ginput_container_select:after {
  height: 42px;
  top: 10px;
  background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/09/caret-down-solid-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.ginput_container_select:after {
  height: 42px;
  top: 10px;
  background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/09/caret-down-solid-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
}

/* font sizes */
.font-18 {
  font-size: 18px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-42 {
  font-size: 42px;
}

.font-16 {
  font-size: 16px;
}

.font-30 {
  font-size: 30px;
}

.font-28 {
  font-size: 28px;
}

.fw-600 {
  font-weight: 600;
}

.font-40 {
  font-size: 40px;
}

.font-35 {
  font-size: 35px;
}

/* end */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 25px !important;
  background-color: #f4f5f7;
  background-color: #fff;
  color: #1f2147 !important;
  color: #0077e7 !important;
  border-radius: 0 !important;
  border-radius: 8px !important;
  font-size: 18px !important;
  font-size: 1.8rem !important;
  padding: 20px 16px !important;

  width: 280px !important;

  line-height: 1 !important;
  margin-bottom: 2px !important;
}

.textblack {
  color: #000000;
}

.navheader {
  /* border-bottom: 1px solid #000000; */
}

.phoneno {
  border: 1px solid #01014f;
}

.phoneno:hover,
.phoneno:focus {
  color: #fff;
  border-color: #01014f;
  background: #01014f;
}

.textwhite {
  color: #01014f;
}

.textccc {
  color: #525666;
}

.slectfiled {
  border: 3px solid #01014f;
  width: 350px;
}

.btn-fill {
  padding: 11px 35px;
  background: rgb(162 32 36) !important;
  border-color: rgb(162 32 36) !important;
  box-shadow: 0 20px 20px -10px rgba(0, 119, 227, 0.2);
  border-radius: 35px !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  min-width: 130px !important;
}

.btn-fill-empty {
  padding: 11px 35px;
  background: rgb(162 32 36) !important;
  border-color: rgb(162 32 36) !important;
  box-shadow: 0 20px 20px -10px rgba(0, 119, 227, 0.2);
  border-radius: 35px !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  min-width: 130px !important;
}

/* custome input */
.custom-input {
  background-color: #fff;
  color: #000052;
  border-radius: 0;
  font-size: 17px;
  font-size: 1.7rem;
  padding: 10px;
  width: 300px;
  height: 70px;
  border: 1px solid #717684;
  line-height: 1;
  margin-bottom: 0;
  font-weight: 500;
}

.custom-input::placeholder {
  color: #5b5252 !important;
}

.alertcard {
  background: #f1f3f9;
  max-width: 479px;
  text-align: center;
  margin: auto;
  color: #0077e7;
  font-style: italic;
  font-weight: 500;
  font-size: 32px;
  padding: 22px;
  border-radius: 23px;
}

.textdark {
  color: #1f2147;
}

html {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}
.before{
  width: 150px;
}
/* How IT Works  */
.wroking {
  background: rgba(245, 240, 235, 1);
}

/* CSS to change accordion button color to red */
.faqs {
  background: rgba(245, 240, 235, 1);
  border-radius: 32px;
}

.accodian-li {
  list-style: disc;
  font-size: 12px;
}

.accordion-button {
  background: #f5f5f7 !important;
  line-height: 1.3;
  cursor: pointer;
  color: #1f2147 !important;
  padding: 22px 14px 22px 46px !important;
  font-size: 2.4rem !important;
  font-weight: 500 !important;
  height: 75px !important;
  border: 0;
  border-radius: 5px !important;
}

code {
  color: #000000 !important;
  font-weight: 600;
}

/* lives */
.lives {
  background: rgba(31, 33, 71, 1);
}

.textwhitee {
  color: #fff;
}

.iconecolor {
  background-color: #01014f;
  color: #f1f3f9;
}

.cardcontent {
  background-color: #f1f3f9;
  border-radius: 20px 20px 15px 15px;
}

.bg-colorCount {
  background-color: #f1f3f9;
}

.imgcard {
  border-radius: 15px 15px 0px 0px;
}

.line {
  border-left: 1px solid black;
}

.quote {
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(31, 33, 71);
}

.textblue {
  color: #00004e;
}

.dark {
  background: #1a1b24;
  color: #fff;
}

.callus {
  background: #0074e0;
  border-color: #0074e0;
  box-shadow: 0 20px 20px -10px rgba(0, 119, 227, 0.2);
  border-radius: 30px;
}

.textblued {
  color: rgb(162 32 36) !important;
  font-size: 60px;
}

.bg-colorwhite {
  background-color: #f1f3f9;
}

.textgreen {
  color: #0077e7;
}

.graycolor {
  font-weight: 400;
  font-size: 16px;
  color: #75757c;
}

.stars {
  width: 150px;
}

/* tabs */
.nav-pills{
  width: 100%;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(162 32 36) !important;
  border: 3px solid #1d1dc917 !important;
  box-shadow: 0px 12px 24px 0px #A9A9AD17 !important;
  width: 100%;

}
.nav-pills .nav-link, .nav-pills .show>.nav-link {
  color: black !important;
  background-color: transparent !important;
  border: 3px solid #1d1dc917 !important;
  box-shadow: 0px 12px 24px 0px #A9A9AD17 !important;
  width: 100%;
  padding: 15px;
  border-radius: 10px !important;

}
.nav-link:focus, .nav-link:hover {
  color: black !important;
  background-color: transparent !important;
    border-color: #000000;
}
.nav-link{
  color: #000000 !important;
}

/* new code end */

/* -------------Common-class---------- */

/* -----media----*/

@media (max-width: 576px) {
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    width: 325px !important;
  }

  .stars {
    width: 50px;
  }

  .font-42 {
    font-size: 24px;
  }

  .btn-fill {
    min-width: 395px !important;
  }

  .btn-fill-empty {
    min-width: 170px !important;
  }

  .accordion-button {
    font-size: 14px !important;
  }

  .font-18 {
    font-size: 10px;
  }

  .font-40 {
    font-size: 12px;
  }

  .textblued {
    font-size: 38px !important;
  }
}